import { useLayoutEffect } from 'react'
import { useReadLocalStorage } from 'usehooks-ts'

import InactivityHandler from '@shared/components/src/InactivityHandler'
import KeepSessionAlive from '@shared/components/src/KeepSessionAlive'
import Loader from '@shared/components/src/Loader'
import useLoadingState from '@shared/hooks/src/useLoadingState'
import DropdownOptionsProvider, { useDropdownOptions } from '@shared/providers/src/DropdownOptionsProvider'
import FeatureFlagsProvider, { useFeatureFlagsQuery } from '@shared/providers/src/FeatureFlagsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import SettingsProvider, { useSettings } from '@shared/providers/src/SettingsProvider'
import { BuildEnv, UserRole } from '@shared/utils'

import QPhrasesProvider from '@providers/QPhrasesProvider'
import Layout from '@components/Layout'
import Notifications from '@components/Notifications'
import QPhrasesInputHandler from '@components/QPhrasesInputHandler'
import { inactivityTime } from '@config'

import LayoutStateProvider from '../LayoutStateProvider'
import MeProvider from '../MeProvider'
import VoiceWidgetStateProvider from '../VoiceWidgetStateProvider'
import useCacheRefresh from './AuthenticatedProviders.hooks'

export default function AuthenticatedProviders() {
  const keepAlive = useReadLocalStorage('keepSessionAlive')

  return (
    <DropdownOptionsProvider>
      <SettingsProvider>
        <FeatureFlagsProvider>
          <MeProvider>
            <QPhrasesProvider>
              <Splash>
                <VoiceWidgetStateProvider>
                  <LayoutStateProvider>
                    <Layout />
                  </LayoutStateProvider>
                </VoiceWidgetStateProvider>
                <Notifications />
                <QPhrasesInputHandler />
                <InactivityHandler interval={inactivityTime} />
                {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && keepAlive && <KeepSessionAlive />}
              </Splash>
            </QPhrasesProvider>
          </MeProvider>
        </FeatureFlagsProvider>
      </SettingsProvider>
    </DropdownOptionsProvider>
  )
}

/**
 * Screen used for preparing the application for usage.
 * It will show a loader until app is ready to be shown
 */
function Splash({ children }) {
  const me = useMe()
  const settings = useSettings()
  const options = useDropdownOptions()
  const { flags } = useFeatureFlagsQuery()

  // Clears the react-query cache when the `refresh` query param is present under whole /app
  useCacheRefresh()

  // Redirect patients to patient app
  useLayoutEffect(() => {
    if (!me || me.role !== UserRole.Patient) return

    window.location.href = import.meta.env.VITE_PATIENT_DOMAIN
  }, [me])

  // Capture timezone for providers
  useLayoutEffect(() => {
    if (me && me.role === UserRole.Provider && me.provider.timezone) {
      window.timezone = me.provider.timezone
    }
    return () => {
      window.timezone = undefined
    }
  }, [me])

  const loading = useLoadingState(!me || !settings || !flags || !options || me.role === UserRole.Patient)

  if (loading) return <Loader />

  return children
}
