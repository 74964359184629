import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import useUserSetting from '@shared/hooks/src/useUserSetting'
import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import { RoleGuard, useMe } from '@shared/providers/src/MeProvider'
import { UserRole, UserSettingName } from '@shared/utils'

import { useLayoutState } from '@providers/LayoutStateProvider'
import {
  BankOutlinedIcon,
  CalendarOutlinedIcon,
  ClockCircleOutlinedIcon,
  CommentOutlinedIcon,
  DashboardOutlinedIcon,
  ExperimentOutlinedIcon,
  FieldTimeOutlinedIcon,
  GuardedIcon,
  LineChartOutlinedIcon,
  NotificationOutlinedIcon,
  PillIcon,
  SettingOutlinedIcon,
  SolutionOutlinedIcon,
  TeamOutlinedIcon,
  UserAddOutlinedIcon,
  UsergroupAddOutlinedIcon,
} from '@icons'
import { Box, Chip, Divider as DividerMui, List, ListItemText, Typography } from '@mui-components'
import SimpleBar from '@components/SimpleBar'
import { rubyLink } from '@config'

import { NavCollapse, NavItem } from './components'

const styles = {
  simpleBar: {
    '& .simplebar-content': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  container: {
    '& > ul:first-of-type': { mt: 0 },
  },
  list: {
    mt: 0,
    py: 0,
    zIndex: 0,
  },
}

export default function Menu() {
  const [open] = useLayoutState()
  const me = useMe()
  const { pathname } = useLocation()

  const cbosOnReact = useFeatureFlag(FeatureFlag.CBOsOnReact)
  const systemUsersOnReact = useFeatureFlag(FeatureFlag.SystemUsersOnReact)
  const providersOnReact = useFeatureFlag(FeatureFlag.ProvidersOnReact)
  const accessRights = useUserSetting(me, UserSettingName.AccessRights)
  const hasAccessToBlackoutPeriods = accessRights?.value?.blackout_periods === true

  /*
   * List of all common sidebar items
   */
  const sidebarItemList = useMemo(() => {
    const isProvider = me.role === UserRole.Provider

    return {
      dashboard: <NavItem title="Dashboard" icon={DashboardOutlinedIcon} selected={pathname === '/app'} url="/app" />,
      patients: <NavItem title="Patients" icon={UserAddOutlinedIcon} selected={pathname === '/app/patients'} url="/app/patients" />,
      messages: (
        <NavItem
          title="Messages"
          icon={CommentOutlinedIcon}
          action={<Notification color="warning" count={me.unreadConversationsCount} />}
          url="/app/conversations"
          selected={pathname.startsWith('/app/conversations')}
        />
      ),
      appointments: (
        <NavItem
          title="Appointments"
          icon={CalendarOutlinedIcon}
          url={`/app/appointments?status=Scheduled_Waiting${isProvider ? `&provider=${me.provider.id}` : ''}`}
          selected={pathname === '/app/appointments'}
        />
      ),
      labs: (
        <NavItem
          title="Labs"
          icon={ExperimentOutlinedIcon}
          action={<Notification color="warning" count={me.unreviewedLabsCount} />}
          url={`/app/labs?status=Needs%20Review_Released_Nurse%20Reviewed_Needs%20Follow-up${isProvider ? `&patientProvider=${me.provider.id}` : ''}`}
          selected={pathname === '/app/labs'}
        />
      ),
      prescriptions: (
        <NavItem title="Prescriptions" icon={PillIcon} url="/app/prescriptions" selected={pathname === '/app/prescriptions'} />
      ),
      quarterlyFlows: (
        <NavItem
          key="quarterlyFlows"
          title="Q Flow Labs"
          level={2}
          external
          url={rubyLink('quarterly_lab')}
          action={<Notification color="warning" count={me.pendingFlows} />}
        />
      ),
      quarterlyHAs: (
        <NavItem
          key="has"
          title="Health Assessments"
          level={2}
          external
          url={rubyLink('assessments')}
          action={<Notification color="warning" count={me.pendingAssessments} />}
        />
      ),
      notificationLog: <NavItem title="Notification Log" icon={NotificationOutlinedIcon} external url={rubyLink('notifications')} />,
      scheduling: (
        <NavItem
          title="Scheduling"
          icon={ClockCircleOutlinedIcon}
          url="/app/provider-scheduling"
          selected={pathname === '/app/provider-scheduling'}
        />
      ),
      reports: <NavItem title="Reports" icon={LineChartOutlinedIcon} url="/app/reports" selected={pathname === '/app/reports'} />,
      providers: (
        <NavItem
          title="Providers"
          icon={UsergroupAddOutlinedIcon}
          external={!providersOnReact}
          url={providersOnReact ? `/app/providers` : rubyLink('providers')}
          selected={pathname.startsWith('/app/providers')}
        />
      ),
      pharmacies: (
        <NavItem title="Pharmacies" icon={BankOutlinedIcon} url="/app/pharmacies" selected={pathname.startsWith('/app/pharmacies')} />
      ),
      cbos: (
        <NavItem
          title="CBOs"
          icon={SolutionOutlinedIcon}
          external={!cbosOnReact}
          url={cbosOnReact ? `/app/cbos` : rubyLink('cbos')}
          selected={pathname.startsWith('/app/cbos')}
        />
      ),
      users: (
        <NavItem
          title="System Users"
          icon={TeamOutlinedIcon}
          external={!systemUsersOnReact}
          url={systemUsersOnReact ? `/app/users` : rubyLink('system_users')}
          selected={pathname.startsWith('/app/users')}
        />
      ),
      platformSettings: (
        <NavItem
          title="Platform Settings"
          icon={SettingOutlinedIcon}
          url="/app/admin/platform-settings"
          selected={pathname === '/app/admin/platform-settings'}
        />
      ),
      superAdmin: <NavItem title="Super Admin" icon={GuardedIcon} url={rubyLink('super_admin_actions')} />,
    }
  }, [me, cbosOnReact, providersOnReact, systemUsersOnReact, pathname])

  return (
    <SimpleBar sx={styles.simpleBar}>
      <Box sx={[styles.container, { pt: open ? 2 : 0 }]}>
        <List sx={styles.list}>
          {sidebarItemList.dashboard}

          {/* --------------- */}
          {/* Provider        */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.Provider]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.pharmacies}
            {sidebarItemList.reports}
            <NavCollapse
              title="Q Flow"
              icon={FieldTimeOutlinedIcon}
              action={<Notification color="warning" count={me.pendingFlows + me.pendingAssessments} />}
            >
              {[sidebarItemList.quarterlyFlows, sidebarItemList.quarterlyHAs]}
            </NavCollapse>
          </RoleGuard>

          {/* --------------- */}
          {/* MA              */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.MA]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.pharmacies}
            {sidebarItemList.reports}
          </RoleGuard>

          {/* --------------- */}
          {/* Support              */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.Support]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.pharmacies}
            {sidebarItemList.reports}
            <Divider />
            {sidebarItemList.notificationLog}
          </RoleGuard>

          {/* --------------- */}
          {/* Admin           */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.Admin]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.reports}

            <Divider />

            {open && (
              <ListItemText
                sx={{ pl: 3, fontWeight: '700' }}
                primary={
                  <Typography variant="h6" color="secondary">
                    Administration
                  </Typography>
                }
              />
            )}

            {systemUsersOnReact ? null : sidebarItemList.providers}
            {sidebarItemList.pharmacies}
            {sidebarItemList.cbos}
            {sidebarItemList.users}
            {sidebarItemList.notificationLog}
            {(me.superAdmin || hasAccessToBlackoutPeriods) && sidebarItemList.platformSettings}
            {me.superAdmin && sidebarItemList.superAdmin}
          </RoleGuard>

          {/* --------------- */}
          {/* CBO             */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.CBO]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.reports}
          </RoleGuard>

          {/* --------------- */}
          {/* Pharmacy        */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.Pharmacy]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.reports}
          </RoleGuard>

          {/* --------------- */}
          {/* Manager         */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.Manager]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.pharmacies}
            {sidebarItemList.reports}
          </RoleGuard>

          {/* --------------- */}
          {/* Clinical Admin  */}
          {/* --------------- */}
          <RoleGuard allowed={[UserRole.ClinicalAdmin]}>
            {sidebarItemList.patients}
            {sidebarItemList.messages}
            {sidebarItemList.appointments}
            {sidebarItemList.labs}
            {sidebarItemList.prescriptions}
            {sidebarItemList.scheduling}
            {sidebarItemList.pharmacies}
            {sidebarItemList.reports}
          </RoleGuard>
        </List>
      </Box>
    </SimpleBar>
  )
}

function Notification({ count = 0, color = 'primary' }) {
  if (!count) return null

  return <Chip size="small" color={color} label={count > 99 ? '99+' : count} sx={{ borderRadius: 4 }} />
}

const Divider = styled(DividerMui)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
}))
