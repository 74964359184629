import { useMemo, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import { AppointmentStatus, mapRailsTimezoneToJS } from '@shared/utils'

import { AppointmentScheduleModal } from '@pages/Appointments'
import { AppointmentIcon, EditOutlinedIcon } from '@icons'
import { Button, Link, Stack, Typography } from '@mui-components'
import Property, { CBOProperty, ProviderProperty } from '@components/Details/Property'
import Section from '@components/Details/Section'

import { Status } from '../../Appointments.utils'

export default function DetailsSection({ appointment }) {
  const location = useLocation()
  const isEncounterPage = location.pathname.includes('encounters')
  const providerId = appointment?.provider?.id

  const [patientTime, providerTime] = useMemo(() => {
    const patientTime = getTiming(appointment?.user, appointment)
    const providerTime = getTiming(appointment?.provider, appointment)
    return [patientTime, providerTime]
  }, [appointment])

  return (
    <Section paper mini title="Details" action={<RescheduleButton appointment={appointment} />}>
      <Property label="Reason">
        <Stack direction="row" alignItems="center" spacing={1}>
          <AppointmentIcon type={appointment.type} />
          {isEncounterPage ? (
            <Typography>{appointment.visitReason}</Typography>
          ) : (
            <Link
              component={RouterLink}
              to={`/app/patients/${appointment.userId}/encounters/${appointment.encounterId}`}
              fontWeight="bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              {appointment.visitReason}
            </Link>
          )}
        </Stack>
      </Property>
      <Property label="Status">
        <Status data={appointment} />
      </Property>
      <Property label="Patients's Time">{patientTime}</Property>
      <Property label="Provider's Time">{providerTime}</Property>
      {providerId && <ProviderProperty label="Provider" user={appointment.provider} />}
      <CBOProperty label="CBO" data={appointment.cbo} />
    </Section>
  )
}

DetailsSection.Loading = () => (
  <Section paper mini title="Details">
    <Property.Loading label="Reason" />
    <Property.Loading label="Status" />
    <Property.Loading label="Patients's Time" />
    <Property.Loading label="Provider's Time" />
    <ProviderProperty.Loading label="Provider" />
    <CBOProperty.Loading label="CBO" />
  </Section>
)

function RescheduleButton({ appointment }) {
  const [open, setOpen] = useState(false)

  if (appointment.status !== AppointmentStatus.Scheduled) return null

  return (
    <>
      <Button color="secondary" onClick={() => setOpen(true)} startIcon={<EditOutlinedIcon />}>
        Reschedule
      </Button>
      <AppointmentScheduleModal
        slide
        open={open}
        appointment={appointment}
        patient={appointment.user}
        encounterId={appointment.encounterId}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

const getTiming = (user, appointment) => {
  if (!appointment) return '—'

  const tz = mapRailsTimezoneToJS(user?.timezone)
  const start = dayjs(appointment.start).tz(tz)

  let time
  if (appointment.duration) {
    time = `${start.format('LT')} - ${start.add(appointment.duration, 'minutes').format('LT z')}`
  } else {
    time = start.format('LT z')
  }

  return `${start.format('L')}, ${time}`
}
