import { DownOutlinedIcon } from '@icons'

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme) {
  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <DownOutlinedIcon style={{ fontSize: '0.75rem' }} />,
      },
    },
  }
}
