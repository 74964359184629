import { useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export const draftKey = (key) => `draft_${key}_data-v1`
const isValidDraftKey = (key) => /^draft_.+_data-v1$/.test(key)

// Clean up old drafts from localStorage to prevent memory leaks and stale data
// Doing the migration may carry over old issues with the drafts, so it's better to clean them up
const cleanUp = () => {
  localStorage.removeItem('thread-drafts-expiration-hours')
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && (/^thread-.+_message$/.test(key) || /^thread-v2-.+_message$/.test(key) || /^patient-.+_message$/.test(key))) {
      localStorage.removeItem(key)
    }
  }
}

// Clear expired drafts from localStorage
const clearExpiredDrafts = (hours) => {
  const now = Date.now()
  const expiryTime = hours * 60 * 60 * 1000

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && isValidDraftKey(key)) {
      const item = localStorage.getItem(key)
      if (item) {
        const { date } = JSON.parse(item)
        if (date + expiryTime < now) {
          localStorage.removeItem(key)
        }
      }
    }
  }
}

const useDraft = (key, initialValue = '') => {
  const storageKey = draftKey(key)
  const [draft, setDraft] = useLocalStorage(storageKey, { data: initialValue, date: Date.now() })

  const removeDraft = useCallback(() => {
    setDraft(undefined)
    localStorage.removeItem(storageKey)
  }, [setDraft, storageKey])

  const setDraftWithDate = useCallback(
    (data) => {
      if (!data) return removeDraft()
      setDraft({ data, date: Date.now() })
    },
    [removeDraft, setDraft]
  )

  return {
    draft: draft?.data ?? initialValue,
    setDraft: setDraftWithDate,
    removeDraft,
  }
}

cleanUp()
clearExpiredDrafts(localStorage.getItem('draft-expiration-hours') || 72)

export default useDraft
