import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'
import { keyToString, UserRole } from '@shared/utils'

export const UserCardKeys = {
  user: (userId) => keyToString(['user-card', userId]),
}

export const UserPharmaciesKey = (userId) => keyToString(['user-pharmacies', userId])

const UsersApi = {
  userCard: (userId, config) =>
    api
      .get(`/users/${userId}/info_card`, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data)
      .then(mapUserCard),
  updateUser: (userId, data, config) =>
    api.patch(`/users/${userId}`, data, config).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),

  updateUserPicture: (userId, data, config) => api.post(`/users/${userId}/profile_picture`, data, config).then((res) => res.data),
  removeUserPicture: (userId, config) => api.delete(`/users/${userId}/profile_picture`, config).then((res) => res.data),

  resetPassword: (userId) => api.post(`/users/${userId}/reset_password_email`),
  updatePassword: (userId, data, config) => api.patch(`/users/${userId}/password`, data, config).then((res) => res.data),

  pharmacies: (userId) => api.get(`/users/${userId}/pharmacies`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
  updatePharmacy: (userId, pharmacyId) =>
    api.patch(`/users/${userId}/pharmacies/${pharmacyId}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data),
}

export default UsersApi

function mapUserCard(data) {
  return {
    id: data.id,
    fullName: data.fullname,
    phone: data.phone,
    profilePictureThumbUrl: data.profilePictureThumbUrl,
    role: data.role,
    pronouns: data.pronouns,
    timezone: data.timezone,
    outOfOfficeMessage: data.outOfOfficeMessage,
    userSettings: data.userSettings,
    disabled: data.disabled,
    ...(data.role === UserRole.Provider && {
      fullName: data.providerFullName,
      provider: {
        id: data.providerId,
        providerTypes: data.providerTypes,
        licenses: data.licenses,
        homeAddress: {
          city: data.providerCity,
          state: data.providerStateAbbreviation,
        },
      },
    }),
    ...(data.role === UserRole.CBO && {
      cbo: {
        name: data.cboName,
      },
    }),
    ...(data.role === UserRole.Pharmacy && {
      pharmacy: {
        name: data.pharmacyName,
      },
    }),
    ...(data.role === UserRole.Patient && {
      enrolled: data.enrolled,
      consent: data.consent,
      patient: {
        treatmentTypes: data.treatmentTypes,
        homeAddress: {
          city: data.homeAddressCity,
          state: data.homeAddressState,
        },
        ...(data.providerFullName && {
          provider: {
            fullName: data.providerFullName,
            profilePictureThumbUrl: data.providerProfilePictureThumbUrl,
          },
        }),
        cbo: {
          name: data.cboName,
        },
        pharmacy: {
          name: data.pharmacyName,
        },
      },
    }),
  }
}
