import { lazy } from 'react'
import { createBrowserRouter, Outlet } from 'react-router-dom'

import { AuthGuard, RoleGuard } from '@shared/providers/src/MeProvider'
import { UserRole } from '@shared/utils'

import AuthenticatedProviders from '@providers/AuthenticatedProviders'
import Error from '@pages/Misc/Error'
import Forbidden from '@pages/Misc/Forbidden'
import NotAuthorized from '@pages/Misc/NotAuthorized'
import NotFound from '@pages/Misc/NotFound'
import { LoadableSuspense } from '@components/Loadable'

import Root from './Root'

const Environment = LoadableSuspense(lazy(() => import('@pages/Environment')))
const AuthRedirect = LoadableSuspense(lazy(() => import('@pages/AuthRedirect')))
const Home = LoadableSuspense(lazy(() => import('@pages/Home')))
const Conversations = LoadableSuspense(lazy(() => import('@pages/Conversations')))
const Conversation = LoadableSuspense(lazy(() => import('@pages/Conversations/Conversation')))
const ConversationEmpty = LoadableSuspense(lazy(() => import('@pages/Conversations/Conversation/Empty')))
const ConversationCompose = LoadableSuspense(lazy(() => import('@pages/Conversations/Conversation/Compose')))
const MyProfile = LoadableSuspense(lazy(() => import('@pages/MyProfile')))
const Encounter = LoadableSuspense(lazy(() => import('@pages/Encounters/Encounter')))
const Appointments = LoadableSuspense(lazy(() => import('@pages/Appointments')))
const Labs = LoadableSuspense(lazy(() => import('@pages/Labs')))
const Lab = LoadableSuspense(lazy(() => import('@pages/Labs/Lab')))
const Prescriptions = LoadableSuspense(lazy(() => import('@pages/Prescriptions')))
const Prescription = LoadableSuspense(lazy(() => import('@pages/Prescriptions/Prescription')))
const Patients = LoadableSuspense(lazy(() => import('@pages/Patients')))
const Patient = LoadableSuspense(lazy(() => import('@pages/Patients/Patient')))
const PatientEncounterRecommendVaccine = LoadableSuspense(lazy(() => import('@pages/Encounters/Encounter/RecommendVaccine')))
const PatientEncounterScheduleAppointment = LoadableSuspense(
  lazy(() => import('@pages/Encounters/Encounter/ScheduleAppointment/ScheduleAppointment'))
)
const PatientEncounterCreateReferral = LoadableSuspense(lazy(() => import('@pages/Encounters/Encounter/CreateReferral')))
const PatientEncounterOrderLabs = LoadableSuspense(lazy(() => import('@pages/Encounters/Encounter/OrderLabs')))
const PatientEncounterOrderRx = LoadableSuspense(lazy(() => import('@pages/Encounters/Encounter/OrderRx')))
const PatientCreate = LoadableSuspense(lazy(() => import('@pages/Patients/Create')))
const PlatformSettings = LoadableSuspense(lazy(() => import('@pages/Admin/PlatformSettings/PlatformSettings')))
const ProviderScheduling = LoadableSuspense(lazy(() => import('@pages/ProviderScheduling')))
const CBOs = LoadableSuspense(lazy(() => import('@pages/CBOs/CBOs')))
const CBORuleSetDetails = LoadableSuspense(lazy(() => import('@pages/CBOs/CBORuleSet/CBORuleSetDetails/CBORuleSetPage')))
const CBORuleSetEdit = LoadableSuspense(lazy(() => import('@pages/CBOs/CBORuleSet/CBORuleSetEdit/CBORuleSetEditPage')))
const Pharmacies = LoadableSuspense(lazy(() => import('@pages/Pharmacies')))
const ContractedPharmacies = LoadableSuspense(lazy(() => import('@pages/Pharmacies/ContractedPharmacies')))
const Reports = LoadableSuspense(lazy(() => import('@pages/Reports')))
const Users = LoadableSuspense(lazy(() => import('@pages/Users')))
const User = LoadableSuspense(lazy(() => import('@pages/Users/User')))
const UserUpdate = LoadableSuspense(lazy(() => import('@pages/Users/UpdateUser')))

const Login = LoadableSuspense(lazy(() => import('@pages/Auth/Login')))
const Logout = LoadableSuspense(lazy(() => import('@pages/Auth/Logout')))
const LoginAs = LoadableSuspense(lazy(() => import('@pages/Auth/LoginAs')))
const CreatePassword = LoadableSuspense(lazy(() => import('@pages/Auth/CreatePassword')))
const ForgotPassword = LoadableSuspense(lazy(() => import('@pages/Auth/ForgotPassword')))
const UpdatePassword = LoadableSuspense(lazy(() => import('@pages/Auth/UpdatePassword')))
const Visit = LoadableSuspense(lazy(() => import('@pages/Visit')))
const StandaloneAppointmentCall = LoadableSuspense(lazy(() => import('@pages/Standalone/AppointmentCall')))
const StandalonePatientCall = LoadableSuspense(lazy(() => import('@pages/Standalone/PatientCall')))
const StandaloneUserCall = LoadableSuspense(lazy(() => import('@pages/Standalone/UserCall')))
const Redirect = LoadableSuspense(lazy(() => import('@pages/Redirect')))
const Redirects = LoadableSuspense(lazy(() => import('@pages/Redirects')))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <AuthRedirect />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/update-password',
        element: <UpdatePassword />,
      },
      {
        path: '/create-password',
        element: <CreatePassword />,
      },
      {
        path: '/app',
        element: (
          <AuthGuard>
            <AuthenticatedProviders />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <Home />,
          },
          {
            path: 'profile',
            element: <MyProfile />,
          },
          {
            path: 'conversations',
            element: <Conversations />,
            children: [
              {
                path: '',
                element: <ConversationEmpty />,
              },
              {
                path: 'compose',
                element: <ConversationCompose />,
              },
              {
                path: ':id',
                element: <Conversation />,
              },
            ],
          },
          {
            path: 'appointments',
            element: (
              <RoleGuard redirect prohibited={[UserRole.Pharmacy]}>
                <Appointments />
              </RoleGuard>
            ),
          },
          {
            path: 'labs',
            children: [
              {
                path: '',
                element: <Labs />,
              },
              {
                path: ':id',
                element: <Lab />,
              },
            ],
          },
          {
            path: 'prescriptions',
            children: [
              {
                path: '',
                element: <Prescriptions />,
              },
              {
                path: ':id',
                element: <Prescription />,
              },
            ],
          },
          {
            path: 'patients',
            children: [
              {
                path: '',
                element: <Patients />,
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    element: <Patient />,
                  },
                  {
                    path: 'encounters/:encounterId',
                    children: [
                      {
                        path: '',
                        element: <Encounter />,
                      },
                      {
                        path: 'appointments/schedule',
                        element: (
                          <RoleGuard redirect allowed={[UserRole.Admin, UserRole.Provider, UserRole.CBO, UserRole.MA, UserRole.Support]}>
                            <PatientEncounterScheduleAppointment />
                          </RoleGuard>
                        ),
                      },
                      {
                        path: 'create-referral',
                        element: (
                          <RoleGuard redirect allowed={[UserRole.Provider]}>
                            <PatientEncounterCreateReferral />
                          </RoleGuard>
                        ),
                      },
                      {
                        path: 'recommend-vaccines',
                        element: (
                          <RoleGuard redirect allowed={[UserRole.Provider]}>
                            <PatientEncounterRecommendVaccine />
                          </RoleGuard>
                        ),
                      },
                      {
                        path: 'order-labs',
                        element: (
                          <RoleGuard redirect allowed={[UserRole.Admin, UserRole.Provider, UserRole.MA]}>
                            <PatientEncounterOrderLabs />
                          </RoleGuard>
                        ),
                      },
                      {
                        path: 'order-rx',
                        element: (
                          <RoleGuard redirect allowed={[UserRole.Provider]}>
                            <PatientEncounterOrderRx />
                          </RoleGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'new',
                element: (
                  <RoleGuard redirect allowed={[UserRole.Admin, UserRole.CBO, UserRole.Support]}>
                    <PatientCreate />
                  </RoleGuard>
                ),
              },
            ],
          },
          {
            path: 'cbos',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard redirect allowed={[UserRole.Admin]}>
                    <CBOs />
                  </RoleGuard>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    path: 'rule-set',
                    children: [
                      {
                        path: '',
                        element: <CBORuleSetDetails />,
                      },
                      {
                        path: 'edit',
                        element: <CBORuleSetEdit />,
                      },
                    ],
                  },
                  {
                    path: 'contracted-pharmacies',
                    element: <ContractedPharmacies />,
                  },
                ],
              },
            ],
          },
          {
            path: 'pharmacies',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard
                    redirect
                    allowed={[UserRole.Admin, UserRole.ClinicalAdmin, UserRole.MA, UserRole.Provider, UserRole.Support, UserRole.Manager]}
                  >
                    <Pharmacies />
                  </RoleGuard>
                ),
              },
            ],
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard redirect allowed={[UserRole.Admin]}>
                    <Users />
                  </RoleGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <RoleGuard redirect allowed={[UserRole.Admin]}>
                    <UserUpdate />
                  </RoleGuard>
                ),
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    children: [
                      {
                        path: '',
                        element: <User />,
                      },
                      {
                        path: ':tab',
                        element: <User />,
                      },
                    ],
                  },
                  {
                    path: 'edit',
                    element: (
                      <RoleGuard redirect allowed={[UserRole.Admin]}>
                        <UserUpdate />
                      </RoleGuard>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'provider-scheduling',
            element: (
              <RoleGuard
                redirect
                allowed={[UserRole.Admin, UserRole.ClinicalAdmin, UserRole.Support, UserRole.MA, UserRole.Manager, UserRole.Provider]}
              >
                <ProviderScheduling />
              </RoleGuard>
            ),
          },
          {
            path: 'reports',
            element: <Reports />,
          },
          {
            path: 'admin/platform-settings',
            element: (
              <RoleGuard redirect allowed={[UserRole.Admin]}>
                <PlatformSettings />
              </RoleGuard>
            ),
          },
          {
            path: 'visits/:id',
            element: (
              <RoleGuard redirect allowed={[UserRole.Provider]}>
                <Visit />
              </RoleGuard>
            ),
          },
          {
            path: 'login-as',
            element: (
              <RoleGuard redirect allowed={[UserRole.Admin, UserRole.Support]}>
                <LoginAs />
              </RoleGuard>
            ),
          },
          /**
           * Separate page for handling internal app links.
           * This is used instead of direct links
           * because URLs can change and links will not be valid anymore.
           * Also, this allows us to handle links with additional logic.
           */
          {
            path: 'redirects/:value',
            element: <Redirects />,
          },
        ],
      },
      {
        path: '/call',
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [
          {
            path: 'appointment/:id',
            element: <StandaloneAppointmentCall />,
          },
          {
            path: 'patient/:id',
            element: <StandalonePatientCall />,
          },
          {
            path: 'user/:id',
            element: <StandaloneUserCall />,
          },
        ],
      },
      {
        path: '/environment',
        element: <Environment />,
      },
      /**
       * Acts as a deep link entrypoint.
       * When a user follows a deep link and requires authentication,
       * once auth is completed, they are sent back here.
       * The url will include a `to` query param with the deep link.
       */
      {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        path: '*',
        children: [
          {
            path: '401',
            element: <NotAuthorized />,
          },
          {
            path: '404',
            element: <NotFound />,
          },
          {
            path: '500',
            element: <Error />,
          },
          {
            path: '403',
            element: <Forbidden />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
])

export default router
